// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeUrlCallback: window.location.origin + '/order-checkout?proceedPayment=true',
  apiUrl: `https://api1.biborne.com`,
  backofficeapiUrl: `https://api-backoffice.biborne.com`,
  // backofficeapiUrl: `http://localhost:8080`,
  java_api: "https://api-version.pro-tactile.com",
  clickversion: "V.5.0.21",
  currency: "€",
  franchiseUUID: 680,
  franchiseID: 680,
  franchiseUUIDEmail: null,
  has_fid: true,
};
export const filigrane = "Click&Collect Biborne";
export const resto_name = "Click&Collect Biborne";
export const phone = "00 00 00 00 00";

/*
 * For easier debugging in development mode, you can import the following file.
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
