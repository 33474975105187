import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientService } from '../../services/client.service';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { UserService } from 'src/app/services/user.service';
import { AppUserService } from 'src/app/managers/app-user.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';

class Client {
  constructor(
    public promotion: boolean,
    public has_fid: boolean,
    public _id: string,
    public login: string,
    public password: string,
    public first_name: string,
    public last_name: string,
    public email: string,
    public franchiseUUID: number
  ) {}
}

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {
  searchTerm: string = ''; // Terme de recherche
  clients: any = [];
  clientsSlice: any = [];
  imageLogo: any = "";
  fideliteVersion: String = null;
  loginError: String = null;
  loading: Boolean = false;
  onSuccess = new EventEmitter();
  franchiseUserData: any;
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";

  constructor(
    private httpClient: HttpClient,
    private clientService: ClientService,
    private router: Router,
    private userService: UserService,
    private mAppUserService: AppUserService,
    private dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    this.franchiseUserData = this.mAppUserService.getFranchiseUser();
    this.clients = await this.fetchUsersFranchise(this.franchiseUserData.id);
    this.clientsSlice = this.clients.slice(0, 50);
    this.getimageLogo();
    this.getFideliteVersion();
    this.getbackgroundColorOpacity();
    this.getRestaurants();
}

getbackgroundColorOpacity() {
  this.httpClient
    .get<any>(
      `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
    )
    .subscribe((backgroundColorOpacityresponse) => {
      this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
      this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
    });
}


private fetchUsersFranchise(franchiseUUID: any) {
return new Promise(async (resolve, reject) => {
    this.clientService.getClients(franchiseUUID).subscribe(res => {
        resolve(res);
    }, _ => {
        reject(null);
    });
})
}

getimageLogo() {
this.httpClient
  .get<any>(
    `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
  )
  .subscribe((logoresponse : any) => {
    this.imageLogo = logoresponse ? logoresponse.image_url : "../../../assets/images/main-logo.png";
  });
}
    
getFideliteVersion() {
  this.httpClient
    .get<any>(
      `${environment.backofficeapiUrl}/api/ClickncollectParam/published/${environment.franchiseID}`
    )
    .subscribe((clickncollectparamresponse) => {
      this.fideliteVersion = clickncollectparamresponse && clickncollectparamresponse.fideliteVersion ? clickncollectparamresponse.fideliteVersion : 'Postgres';
    });
}

navigate_account(mFormData: any, path: string) {
  // console.log(client);
  // this.router.navigate(["authenticate"]);

  this.userService.loginUserNewWithoutPassword(mFormData).subscribe((res: any) => {
    const userData = res;
    this.loading = false;
    if (userData && userData.token && userData.token != "err") {
        this.mAppUserService.setUser(userData);
        this.onSuccess.emit(userData);
        // Ouvrir dans un nouvel onglet privé
        window.open('/' + path, '_blank');
        // window.open('/call-restos', '_blank', 'private,location=no,menubar=no');
        // this.router.navigate(["call-restos"]);
        // this.router.navigate(["account-profil"]);
    } else {
        this.loginError = "Couple login/mot de passe est incorrect.";
    }
  }, (_) => {
      this.loading = false;
      this.loginError = "Couple login/mot de passe est incorrect.."; 
  });

}

checkSearchTermLength() {
  if (this.searchTerm.length === 10 && this.clientsSlice.length === 0) {
    this.nouveauClient();
  } else {
    // Gérez le cas où la longueur de searchTerm n'est pas de 10 caractères
  }
}

nouveauClient() {
  this.mAppUserService.setNewCustomerPhone(this.searchTerm);
  this.mAppUserService.logoutUser();
  this.router.navigate(["nouveau-client"]);
}

matchSearchTerm(client: any): boolean {
  // Cette fonction vérifie si le client correspond au terme de recherche (searchTerm)
  if (!this.searchTerm) {
    // Si searchTerm est vide, tous les clients correspondent
    return true;
  }

  const searchTerm = this.searchTerm.toLowerCase();
  
  // Sinon, vérifiez si le login du client inclut le terme de recherche (en ignorant la casse)
  return ((client.login && client.login.toLowerCase().includes(searchTerm))
  || client.first_name && client.first_name.toLowerCase().includes(searchTerm)
  || client.last_name && client.last_name.toLowerCase().includes(searchTerm));
}

searchAllClients() {
  // Effectuez une recherche dans l'ensemble des clients
  if (this.searchTerm) {
    this.clientsSlice = this.clients.filter((client: any) => this.matchSearchTerm(client));
  } else {
    // Si le terme de recherche est vide, affichez les 50 premiers clients
    this.clientsSlice = this.clients;
  }
}

getRestaurants() {
  this.httpClient
    .get(`${environment.backofficeapiUrl}/api/franchiseprocaisse/restos/680`)
    .subscribe((response) => {
      localStorage.setItem("restaurants", JSON.stringify(response));
    });
}

confirm_delete_client(client) {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: { message: 'Êtes-vous sûr de vouloir supprimer ce client?<br>Cette action est irréversible.' }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.clientService.deleteClient(client._id).subscribe(
        async (res) => {
          this.dialog.open(AlertDialogComponent, {
            data: { title: "Suppression de client", message: "Le client a été supprimé avec succès." },
          });

          this.clients = await this.fetchUsersFranchise(this.franchiseUserData.id);
          this.clientsSlice = this.clients.slice(0, 50);
        },
        (_) => {
          this.dialog.open(AlertDialogComponent, {
            data: { title: "Suppression de client", message: "Une erreur s'est produite lors de la suppresion du client." },
          });
        }
      );
    }
  });
}
}
