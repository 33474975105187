import { Component, OnInit } from "@angular/core";
import { AppUserService } from "src/app/managers/app-user.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { OrdersClientsService } from "../../services/orders-clients.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { UserService } from "src/app/services/user.service";
import { AppOrderService } from "src/app/managers/app-order.service";
import { OrderTypeEnum } from "src/app/enums/order-type";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { FranchiseService } from "src/app/services/franchise.service";
import { isNgTemplate } from "@angular/compiler";
import { MyOrderService } from "src/app/services/my-order.service";
import * as moment from "moment";

@Component({
  selector: "app-customer-details",
  templateUrl: "./customer-details.component.html",
  styleUrls: ["./customer-details.component.scss"],
})
export class CustomerDetailsComponent implements OnInit {
  AVAILABLE_TYPES = OrderTypeEnum;
  orderType: null;
  customer: any; // Remplacez par le type de données du client
  selectedOrderType: string;
  deliveryAddress: string;
  userData: any;
  userFranchiseData: any;
  backgroundColorOpacity: string = "";
  imageLogo: any;
  formDelivery: FormGroup = null;
  orders: any;
  lastDeliveryOrder: any;
  showDeliveryForm: boolean = true;

  cityGoogleMap: string;
  adresseGoogleMap: string;
  zipCodeGoogleMap: string;

  buildingGoogleMap: string;
  stairsGoogleMap: string;
  entryCodeGoogleMap: string;
  levelGoogleMap: string;
  flatNumberGoogleMap: string;
  intercomGoogleMap: string;
  extraGoogleMap: string;
  privateNote: string;

  // latGoogleMap : any = 33.54001916955293;
  // longGoogleMap : any = -7.643415609097636;
  latGoogleMap: any = null;
  longGoogleMap: any = null;
  durationGoogleMap: string; // Stockera la durée du trajet

  // Coordonnées du client (à remplacer par les coordonnées réelles)
  clientCoords = { lat: null, lng: null };

  // Coordonnées du restaurant (à remplacer par les coordonnées réelles)
  restaurantCoords = { lat: 12.6392316, lng: -8.0028892 };

  options: any = {
    componentRestrictions: { country: "ml" },
  };

  franchise: any;
  restaurants: any;
  myRestaurants: any;
  selectedRestaurant: number; // ou le type approprié pour l'ID du restaurant
  selectedRestaurantCoords: { lat: number; lng: number };

  computingFastestRoute: boolean;

  constructor(
    private userService: UserService,
    private ordersClientsService: OrdersClientsService,
    private httpClient: HttpClient,
    private appUser: AppUserService,
    private router: Router,
    private appOrderService: AppOrderService,
    private appLicenceManager: AppLicenceManagerService,
    private restoManager: AppLicenceManagerService,
    private franchiseService: FranchiseService,
    private myOrderService: MyOrderService
  ) {
    this.userData = appUser.getUser();
    this.userFranchiseData = appUser.getFranchiseUser();

    this.adresseGoogleMap = this.userData.address;
    this.cityGoogleMap = this.userData.city;
    this.zipCodeGoogleMap = this.userData.zipCode;
    this.buildingGoogleMap = this.userData.building;
    this.stairsGoogleMap = this.userData.stairs;
    this.entryCodeGoogleMap = this.userData.entryCode;
    this.levelGoogleMap = this.userData.level;
    this.flatNumberGoogleMap = this.userData.flatNumber;
    this.intercomGoogleMap = this.userData.intercom;
    this.extraGoogleMap = this.userData.extra;
    this.latGoogleMap = this.userData.lat;
    this.longGoogleMap = this.userData.long;
    this.privateNote = this.userData.privateNote;

    this.clientCoords = {
      lat: parseFloat(this.userData.lat),
      lng: parseFloat(this.userData.long),
    };
  }

  async ngOnInit() {
    console.log('Options Google Places appliquées:', this.options);
    
    this.formDelivery = new FormGroup({
      city: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      zipCode: new FormControl("", [Validators.required]),
      building: new FormControl(""),
      stairs: new FormControl(""),
      entryCode: new FormControl(""),
      level: new FormControl(""),
      flatNumber: new FormControl(""),
      intercom: new FormControl(""),
      extra: new FormControl(""),
      lat: new FormControl(""),
      long: new FormControl(""),
      privateNote: new FormControl(""),
    });

    // this.formDelivery.controls["address"].setValue("khourou nar");

    // {{ userData.first_name }} {{ userData.last_name }} | {{ userData.phone }}
    // Exemple : Simulez les détails du client
    this.customer = {
      name: this.userData.first_name + " " + this.userData.last_name,
      phone: this.userData.phone,
      // Ajoutez d'autres détails du client
    };
    // Initialisation
    this.getimageLogo();
    this.getbackgroundColorOpacity();

    this.orders = await this.fetchOrderCustomer(
      this.userFranchiseData.franchise_uuid,
      this.userData.id
    );
    this.lastDeliveryOrder = this.getLastDeliveryOrder(this.orders);

    this.getRestaurants();
    this.getMyRestaurants(environment.franchiseID);
  }

  updateSelectedRestaurantCoords() {
    const selectedFranchise = this.restaurants.find(
      (franchise) => franchise.restos.id === this.selectedRestaurant
    );

    if (
      selectedFranchise &&
      selectedFranchise.restos.lat &&
      selectedFranchise.restos.lng
    ) {
      this.selectedRestaurantCoords = {
        lat: parseFloat(selectedFranchise.restos.lat),
        lng: parseFloat(selectedFranchise.restos.lng),
      };
      this.initMap();
    } else {
      // Gérer le cas où les coordonnées ne sont pas disponibles
      console.error("Coordonnées du restaurant non disponibles.");
    }

    this.appLicenceManager.factory(selectedFranchise.restos.current_licence);
    this.getParams();
  }

  getRestaurants() {
    this.httpClient
      .get(`${environment.backofficeapiUrl}/api/franchiseprocaisse/restos//${environment.franchiseID}`)
      .subscribe((response) => {
        this.restaurants = response;

        const regexArrondissement = /\b(\d+)e\b/g;

        this.restaurants.sort((a, b) => {
          const matchesA = a.restos.pseudo.match(regexArrondissement);
          const matchesB = b.restos.pseudo.match(regexArrondissement);

          const arrondissementA = parseInt(matchesA[0].replace("e", ""));
          const arrondissementB = parseInt(matchesB[0].replace("e", ""));

          return arrondissementA - arrondissementB;
        });

        if (this.clientCoords.lat && this.clientCoords.lng) {
          // select the restaurant with the quickest delivery time to the client
          this.selectFastestRestaurant();
        }
      });
  }

  initMap() {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const map = new google.maps.Map(document.getElementById("map"), {
      center: this.selectedRestaurantCoords
        ? this.selectedRestaurantCoords
        : this.restaurantCoords,
      zoom: 15,
    });

    directionsRenderer.setMap(map);

    const request = {
      origin: this.selectedRestaurantCoords
        ? this.selectedRestaurantCoords
        : this.restaurantCoords,
      destination: this.clientCoords,
      travelMode: google.maps.TravelMode.DRIVING, // Vous pouvez choisir le mode de transport
    };

    directionsService.route(request, (result, status) => {
      if (status == "OK") {
        directionsRenderer.setDirections(result);
        this.durationGoogleMap = result.routes[0].legs[0].duration.text;
      }
    });
  }

  getLastDeliveryOrder(orders) {
    let lastDeliveryOrder = null;
    for (let i = orders.length - 1; i >= 0; i--) {
      if (orders[i].type === "En Livraison") {
        lastDeliveryOrder = orders[i];
        break; // Sortir de la boucle dès qu'on a trouvé la dernière commande de livraison
      }
    }
    return lastDeliveryOrder;
  }

  private fetchOrderCustomer(procaisse_licence_id: any, client_id: string) {
    return new Promise(async (resolve, reject) => {
      this.ordersClientsService
        .getOrdersByLicenceAndClient(procaisse_licence_id, client_id)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (_) => {
            reject(null);
          }
        );
    });
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse
          ? logoresponse.image_url
          : "../../../assets/images/main-logo.png";
      });
  }

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse
          ? backgroundColorOpacityresponse.background_home_opacity
          : null;
      });
  }

  getMyRestaurants(franchiseID) {
    this.franchiseService.getRestosPostgres(franchiseID).subscribe((res) => {
      this.franchise = res;
      this.franchise.img = this.imageLogo;
      this.myRestaurants = this.franchise.restos;
    });
  }

  private getParams() {
    console.log(
      "appLicenceManager licenceId",
      this.appLicenceManager.licenceId
    );
    this.myOrderService
      .fetchOrderParams(this.appLicenceManager.licenceId)
      .subscribe((res) => {
        console.log("resssss", res);

        if (res) {
          const isoWeekDay = moment().isoWeekday(),
            now = moment();
          let mDay = null;
          if (res.days && res.days.length) {
            mDay = res.days.find((d) => {
              return d.day === isoWeekDay;
            });
          }
          let isOpenTime = false;
          if (mDay) {
            mDay.workShifts.forEach((s) => {
              const sHours = parseInt(s.start_shift),
                eHours = parseInt(s.end_shift);
              if (now.hours() >= sHours && now.hours() < eHours) {
                isOpenTime = true;
              }
            });
          }
          if (res.zones && res.zones.length) {
            res.zones = res.zones.map((zone) => {
              zone.cities = zone.cities.map((city) => {
                city.min_order = parseFloat(zone.amount) || 0;
                city.deliveryCostOrder = parseFloat(zone.delivery_costs) || 0;
                return city;
              });
              return zone;
            });

            //TODO: Sort min to max amount
          }

          console.log("res", res);
          this.appLicenceManager.saveParameters(res);
        }
      });
  }

  private selectFastestRestaurant() {
    if (!this.restaurants)
      return;
    
    this.computingFastestRoute = true;

    const directionsService = new google.maps.DirectionsService();
    const routePromises = [];

    this.restaurants.forEach((franchise) => {
      if (franchise.restos && franchise.restos.lat && franchise.restos.lng) {
        const restaurantCoords = {
          lat: parseFloat(franchise.restos.lat),
          lng: parseFloat(franchise.restos.lng),
        };
    
        const request = {
          origin: restaurantCoords,
          destination: this.clientCoords,
          travelMode: google.maps.TravelMode.DRIVING,
        };
    
        const routePromise = new Promise((resolve) => {
          directionsService.route(request, (result, status) => {
            if (status === "OK") {
              const duration = result.routes[0].legs[0].duration.value;
              resolve({ duration, franchise });
            } else {
              resolve(null);
            }
          });
        });
    
        routePromises.push(routePromise);
      }
    });

    Promise.all(routePromises).then((results) => {
      let lowestDurationTime = Infinity;
      let fastestFranchise = null;
    
      results.forEach((result) => {
        if (result && result.duration < lowestDurationTime) {
          lowestDurationTime = result.duration;
          fastestFranchise = result.franchise;
        }
      });

      this.computingFastestRoute = false;
      
      setTimeout(() => {
        this.selectedRestaurant = fastestFranchise.restos.id;
        this.updateSelectedRestaurantCoords();
      }, 100);
    });
  }

  confirmOrder() {
    let item = null;

    for (let i = 0; i < this.myRestaurants.length; i++) {
      let myItem = this.myRestaurants[i];
      console.log("myItem", myItem.id, this.selectedRestaurant);

      if (myItem.id === this.selectedRestaurant) {
        item = myItem;
        break;
      }
    }
    if (!item) {
      return;
    }

    this.appLicenceManager.factory(item.currentLicence);
    // this.secondInit(item);
    this.restoManager.saveResto(item);
    this.router.navigate(["categories"]);
    // Utilisez selectedOrderType et deliveryAddress pour traiter la commande
    // if (this.selectedOrderType === 'livraison') {
    // Le client a choisi la livraison, utilisez this.deliveryAddress
    // pour obtenir l'adresse de livraison et traitez la commande.
    // } else {
    // Le client a choisi un autre type de commande (ex. emporter)
    // Traitez la commande en conséquence.
    // }

    this.appOrderService.factory(OrderTypeEnum.DELIVERY);

    // this.router.navigate(["/call-restos"]);
    localStorage.setItem(
      "formDelivery",
      JSON.stringify(this.formDelivery.value)
    );

    this.save();
  }

  displayDeliveryForm() {
    this.showDeliveryForm = true;
  }
  // }

  //   handleAddressChange(address: Address) {
  //       // Extraire le code postal (zipcode) de formatted_address
  //   const regexZipcode = /(\d{5})/; // Un exemple de regex pour le code postal (5 chiffres)
  //   const matchZipcode = address.formatted_address.match(regexZipcode);
  //   const zipcode = matchZipcode ? matchZipcode[0] : '';

  //   let city = ''; // Initialisez la variable de ville
  //   // Extraire la ville de formatted_address
  //   const parts = address.formatted_address.split(','); // Supposons que la ville est dans la deuxième partie de l'adresse
  //   // const city = parts.length > 1 ? parts[1].trim() : '';
  //   if (parts.length > 1) {
  //     // Si nous avons au moins deux parties
  //     const secondPart = parts[1].trim(); // Récupère la deuxième partie et supprime les espaces de début et de fin

  //     // Divise la deuxième partie en mots
  //     const words = secondPart.split(' ');

  //     if (words.length > 0) {
  //         // Si nous avons au moins un mot, prenons le dernier mot
  //         city = words[words.length - 1];
  //     }
  // }
  //     this.adresseGoogleMap = address && address.formatted_address ? address.formatted_address : this.userData.address;
  //     this.zipCodeGoogleMap = zipcode ? zipcode : this.userData.zipCode;
  //     this.cityGoogleMap = city ? city : this.userData.city;

  //     this.latGoogleMap = address.geometry.location.lat() ? address.geometry.location.lat() : this.userData.lat;
  //     this.longGoogleMap = address.geometry.location.lng() ? address.geometry.location.lng() : this.userData.long;

  //     this.clientCoords = { lat: this.latGoogleMap, lng: this.longGoogleMap };
  //     this.initMap();
  //     // console.log(address.geometry.location.lat());
  //     // console.log(address.geometry.location.lng());
  //   }

  async handleAddressChange(address: Address) {
    let street = "";
    let zipcode = "";
    let city = "";

    for (const component of address.address_components) {
      if (component.types.includes("locality")) {
        city = component.short_name;
      }
      else if(component.types.includes("street_number")) {
        street = component.short_name + " " + street;
      }
      else if(component.types.includes("route")) {
        street += component.short_name;
      }
      else if(component.types.includes("postal_code")) {
        zipcode = component.short_name;
      }
    }

    this.adresseGoogleMap = street ? street : this.userData.address;
    this.zipCodeGoogleMap = zipcode ? zipcode : this.userData.zipCode;
    this.cityGoogleMap = city ? city : this.userData.city;

    this.latGoogleMap = address.geometry.location.lat()
      ? address.geometry.location.lat()
      : this.userData.lat;
    this.longGoogleMap = address.geometry.location.lng()
      ? address.geometry.location.lng()
      : this.userData.long;

    this.clientCoords = { lat: this.latGoogleMap, lng: this.longGoogleMap };
    this.selectFastestRestaurant();
  }

  save() {
    const mData = {
      email: this.userData.email,
      login: this.userData.phone,
      first_name: this.userData.first_name,
      last_name: this.userData.last_name,

      address: this.adresseGoogleMap,
      zipCode: this.zipCodeGoogleMap,
      city: this.cityGoogleMap,
      building: "", //this.formDelivery.get("building").value,
      stairs: "", //this.formDelivery.get("stairs").value,
      entryCode: "", //this.formDelivery.get("entryCode").value,
      level: "", //this.formDelivery.get("level").value,
      flatNumber: "", //this.formDelivery.get("flatNumber").value,
      intercom: "", //this.formDelivery.get("intercom").value,
      extra: this.formDelivery.get("extra").value,
      lat: this.formDelivery.get("lat").value,
      long: this.formDelivery.get("long").value,
      privateNote: this.formDelivery.get("privateNote").value,
    };
    if (mData.address && mData.city) {
      this.userService.updateAddressUser(this.userData.id, mData).subscribe(
        (res) => {
          // this.appSnackBarService.handle({ data: "Votre profil a été bien mis à jour" });
          const oldUser = this.userData.user;
          const newUser = Object.assign({}, oldUser, mData);
          this.userData.setUser(newUser);
        },
        (_) => {}
      );
    }
  }

  setOrderType(mValue) {
    this.save();

    localStorage.setItem(
      "formDelivery",
      JSON.stringify(this.formDelivery.value)
    );

    this.orderType = mValue;
    if (!this.orderType) {
      return;
    }
    this.appOrderService.factory(this.orderType);
    this.router.navigateByUrl("/call-restos");
  }
}
